import React from "react";
import { PageLink } from "../../_metronic/layout/core";

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: "Account",
    path: "/crafted/account/overview",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
];

const AccountPage: React.FC = () => {
  return (
    <div>
      Hello, this is account setting page
    </div>
    // <Routes>
    //   <Route
    //     element={
    //       <>
    //         <AccountHeader />
    //         <Outlet />
    //       </>
    //     }
    //   >
    //     <Route
    //       path="overview"
    //       element={
    //         <>
    //           <PageTitle breadcrumbs={accountBreadCrumbs}>Overview</PageTitle>
    //           <Overview />
    //         </>
    //       }
    //     />
    //     <Route
    //       path="settings"
    //       element={
    //         <>
    //           <PageTitle breadcrumbs={accountBreadCrumbs}>Settings</PageTitle>
    //           <Settings />
    //         </>
    //       }
    //     />
    //     <Route index element={<Navigate to="/crafted/account/overview" />} />
    //   </Route>
    // </Routes>
  );
};

export default AccountPage;
