import clsx from "clsx";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { resetPassword } from "../../api";
import { passwordRegex } from "../../constants/constant";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";

const initialValues = {
  password: "",
  confirmPassword: "",
};

const forgotPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .test(
      "password",
      "Password must be alphanumeric and contains minimum eight characters and at least, one special character and one uppercase letter",
      (value) => passwordRegex.test(String(value)),
    )
    .required("Password is required"),
  confirmPassword: Yup.string()
    .test(
      "confirm-password",
      "Password must be alphanumeric and contains minimum eight characters and at least, one special character and one uppercase letter",
      (value) => passwordRegex.test(String(value)),
    )
    .required("Confirm password is required"),
});

export function ResetPassword() {
  const location: any = useLocation();
  const navigate = useNavigate();
  const { mutateAsync: resetPasswordForgotPassword, isLoading: isLoading } =
    useMutation(resetPassword);

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values) => {
      let { password, confirmPassword } = formik.values;
      if (password !== confirmPassword) {
        formik.setFieldError(
          "confirmPassword",
          "Confirm password should match with the password",
        );
        return;
      }
      let bodyParam = {
        password: values?.password ?? "",
        confirmpassword: values?.confirmPassword ?? "",
        email: location?.state?.email ?? "",
      };

      let response = await resetPasswordForgotPassword(bodyParam);
      if (response?.result) {
        snackActions.success(response?.message ?? "Success");
        navigate("/auth/login");
      } else {
        snackActions.error(response?.message ?? "Something went wrong!");
      }
      // navigate("/auth/send-otp", { state: values });
    },
  });

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="kt_login_password_reset_form"
      onSubmit={formik.handleSubmit}
    >
      <div className="text-center mb-10">
        {/* begin::Title */}
        <h1 className="text-dark fw-bolder mb-3">Reset Password</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className="text-gray-500 fw-semibold fs-6">
          Enter your new password.
        </div>
        {/* end::Link */}
      </div>

      {/* begin::Form group */}
      <div className="fv-row mb-3">
        <label className="form-label fw-bolder text-dark fs-6 mb-0">
          Password
        </label>
        <input
          type="password"
          autoComplete="off"
          {...formik.getFieldProps("password")}
          className={clsx(
            "form-control bg-transparent",
            {
              "is-invalid": formik.touched.password && formik.errors.password,
            },
            {
              "is-valid": formik.touched.password && !formik.errors.password,
            },
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>

      <div className="fv-row mb-3">
        <label className="form-label fw-bolder text-dark fs-6 mb-0">
          Confirm Password
        </label>
        <input
          type="password"
          autoComplete="off"
          {...formik.getFieldProps("confirmPassword")}
          className={clsx(
            "form-control bg-transparent",
            {
              "is-invalid":
                formik.touched.confirmPassword && formik.errors.confirmPassword,
            },
            {
              "is-valid":
                formik.touched.confirmPassword &&
                !formik.errors.confirmPassword,
            },
          )}
        />
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.confirmPassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="d-flex flex-wrap pb-lg-0 pt-4">
        <button
          type="submit"
          id="kt_password_reset_submit"
          className="btn btn-primary me-4"
        >
          <span className="indicator-label">
            {isLoading ? "Loading..." : "Submit"}
          </span>
        </button>
        <Link to="/auth/login">
          <button
            type="button"
            id="kt_login_password_reset_form_cancel_button"
            className="btn btn-light"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Cancel
          </button>
        </Link>{" "}
      </div>
      {/* end::Form group */}
    </form>
  );
}
