import { useFormik } from "formik";
import { useState } from "react";
import { Button, Card, Container, Row } from "react-bootstrap";
import { useMutation } from "react-query";
import * as Yup from "yup";
import { KTCard } from "../../_metronic/helpers";
import { addInfoCheckList } from "../../api";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import CommonDropdown from "./CommonDropdown";

const AddCheckList = ({ title = "" }: { title: string }) => {
  const [inputs, setInputs] = useState([title]);
  const profileDetailsSchema = Yup.object().shape({
    eventList: Yup.string().required("This field is required"),
    checkListTitle: Yup.string().required("This field is required"),
    mainTitle: Yup.string().required("This field is required"),
    // infochirps: Yup.string().required("This field is required"),
  });

  const formik: any = useFormik({
    initialValues: {
      inputs: [title],
      eventList: "",
      infochirps: "",
      mainTitle: "",
      title: "",
    },
    validationSchema: "",
    onSubmit: async (values) => {
      let bodyParams = {
        id: 0,
        eventId: values?.eventList,
        infoChirpId: 11,
        title: values?.mainTitle,
        itemJson: values?.inputs?.map((item: any) => item.title),
      };
      const response = await addCheckList(bodyParams);
      if (response.result) {
        snackActions.success(response?.message ?? "Added Successfully!");
        formik.resetForm();
        setInputs([""]);
      } else {
        snackActions.error(response?.message ?? "Something went wrong!");
      }
    },
  });
  const { mutateAsync: addCheckList, isLoading: isAddingChecklist } =
    useMutation(addInfoCheckList);

  const handleAddFields = () => {
    setInputs([...inputs, title]);
  };

  const handleRemoveFields = (index: any) => {
    const newInputs = [...inputs];
    newInputs.splice(index, 1);
    setInputs(newInputs);
  };

  return (
    <Card className="p-2">
      <>
        <KTCard>
          <>
            <h2 className="mb-8" style={{ color: "#272781" }}>
              {`${title} : `}
            </h2>
            <form onSubmit={formik.handleSubmit} noValidate className="form">
              <CommonDropdown formik={formik} />
              <Container fluid>
                <Row>
                  <div className="d-flex">
                    <div className="flex-grow-1 me-3">
                      <label className="form-label fw-bold">Title</label>
                      <input
                        placeholder="Enter Title"
                        type="text"
                        autoComplete="off"
                        {...formik.getFieldProps("mainTitle")}
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      />
                      {formik.touched.mainTitle && formik.errors.mainTitle && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.mainTitle}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Row>
                <Row>
                  <span
                    onClick={handleAddFields}
                    style={{
                      width: "auto",
                      margin: "5px 10px 0 auto",
                      cursor: "pointer",
                      color: "blue",
                    }}
                  >
                    {/* <i className="bi bi-file-plus-fill fs-4"></i> */}
                    <i className="bi bi-plus-circle-fill fs-4"></i>
                  </span>
                  {inputs.map((input, index: any) => (
                    <div key={index} className="d-flex">
                      <div className="flex-grow-1 me-3">
                        <label className="form-label fw-bold"> Checklist</label>
                        <input
                          placeholder="Enter Checklist"
                          type="text"
                          autoComplete="off"
                          {...formik.getFieldProps(`inputs[${index}].title`)}
                          className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        />
                        {formik.touched.title && formik.errors.title && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {formik.errors.title}
                            </div>
                          </div>
                        )}
                      </div>
                      {index > 0 && (
                        <span onClick={() => handleRemoveFields(index)}>
                          <i className="bi bi-trash-fill text-danger cursor-pointer"></i>
                        </span>
                      )}
                    </div>
                  ))}
                </Row>
              </Container>
              <Button
                type="submit"
                size="lg"
                variant="primary"
                className="d-flex ms-auto mt-6"
              >
                {isAddingChecklist ? "Loading..." : "Submit"}
              </Button>
            </form>
          </>
        </KTCard>
      </>
    </Card>
  );
};

export default AddCheckList;
