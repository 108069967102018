import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { createPortal } from "react-dom";
import { useMutation } from "react-query";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import * as Yup from "yup";
import { KTIcon } from "../../_metronic/helpers";
import { addBlog, editBlog, uploadPicture } from "../../api";
import { charValidate } from "../../components/utility";
import { APP_URLs } from "../../constants/appURLs";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";

type Props = {
    show: boolean;
    handleClose: (isSubmit: boolean) => void;
    editData: any;
};

const modalsRoot = document.getElementById("root-modals") || document.body;

const AddEditBlog = ({ show, handleClose, editData }: Props) => {

    const { mutateAsync: pictureUpload } = useMutation(
        "upload-image",
        uploadPicture,
    );

    let imgUrl: string = editData?.imageUrl ? editData?.imageUrl : "";

    let imgFileNameArray = imgUrl ? imgUrl?.split("/") : "";

    const [imgFileName, setImgFileName] = useState<string>(
        imgFileNameArray ? imgFileNameArray[imgFileNameArray.length - 1] : "",
    );
    const [eventImg, setEventImg] = useState<string>("");


    const blogDetailsSchema = Yup.object().shape({
        title: Yup.string().required("Title is required"),
        imageUrl: Yup.string(),
        redirectUrl: Yup.string().required("Redirect URL is required"),
        description: Yup.string().required("Description is required"),
    });

    const { mutateAsync: addBlogAPI, isLoading: isAdding } = useMutation(
        "add-blog",
        addBlog,
    );
    const { mutateAsync: editBlogAPI, isLoading: isEditing } = useMutation(
        "edit-blog",
        editBlog,
    );

    const formik = useFormik({
        initialValues: {
            id: editData.id ? editData?.id : 0,
            title: editData?.title ?? "",
            imageUrl: editData?.imageUrl ?? "",
            redirectUrl: editData?.redirectUrl ?? "",
            description: editData?.description ?? "",
        },
        validationSchema: blogDetailsSchema,
        onSubmit: async (values) => {
            let bodyParams = {
                id: editData.id ? editData?.id : 0,
                title: values?.title,
                description: values?.description,
                redirectUrl: values?.redirectUrl,
                imageUrl: eventImg ? eventImg : "",
            }
            let response;

            if (editData?.id) {
                response = await editBlogAPI(bodyParams);
                if (response?.result) {
                    snackActions.success(response?.message ?? "Added/Edited Successfully!");
                    formik.resetForm();
                    handleClose(true);
                } else {
                    snackActions.error(response?.message ?? "Something went wrong!");
                }
            } else {
                response = await addBlogAPI(bodyParams);
                if (response?.result) {
                    snackActions.success(response?.message ?? "Added/Edited Successfully!");
                    formik.resetForm();
                    handleClose(true);
                } else {
                    snackActions.error(response?.message ?? "Something went wrong!");
                    handleClose(false);
                }
            }
        },
    });

    useEffect(() => {
        if (imgUrl) {
            setEventImg(imgUrl);
            setImgFileName(
                imgFileNameArray ? imgFileNameArray[imgFileNameArray.length - 1] : "",
            );
        }
    }, [editData]);

    const handleChangeImageUpload = async (e: any) => {
        let file = e?.target?.files[0];
        let formData = new FormData();
        setImgFileName(file?.name ?? "");
        formData.append("files", file);
        formData.append("fileDriveName", "event");
        if (file) {
            let response = await pictureUpload(formData);
            if (response[0]?.isSuccess) {
                setEventImg(`${APP_URLs.mediaBaseURL}${response[0].fileUrl}`);
            } else {
                snackActions.error(
                    response?.message ?? "Issue in image upload, try again latter!",
                );
            }
        }
    };

    return createPortal(
        <Modal
            id="kt_modal_create_app"
            tabIndex={-1}
            aria-hidden="true"
            dialogClassName="modal-dialog modal-dialog-centered mw-600px"
            show={show}
            onHide={() => handleClose(false)}
            backdrop={true}
        >
            <div className="modal-header">
                <h2>Add / Edit Blog</h2>
                {/* begin::Close */}
                <div
                    className="btn btn-sm btn-icon btn-active-color-primary"
                    onClick={() => handleClose(false)}
                >
                    <KTIcon className="fs-1" iconName="cross" />
                </div>
                {/* end::Close */}
            </div>

            <div className="p-6">
                <form onSubmit={formik.handleSubmit} noValidate className="form">
                    <div>
                        <div className="fv-row mb-8">
                            <label className="form-label fw-bolder text-dark fs-6">
                                Title
                            </label>
                            <input
                                placeholder="Title"
                                type="text"
                                autoComplete="off"
                                {...formik.getFieldProps("title")}
                                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                            />
                            {formik.touched.title && formik.errors.title && (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                        <p style={{ color: "red" }}>Title is Required</p>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="fv-row mb-8">
                            <label className="form-label fw-bold">Redirect Url</label>
                            <input
                                placeholder="Redirect Url"
                                type="text"
                                autoComplete="off"
                                {...formik.getFieldProps("redirectUrl")}
                                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                            />
                            {formik.touched.redirectUrl && formik.errors.redirectUrl && (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                        <p style={{ color: "red" }}>Redirect Url is Required</p>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="fv-row">
                            <label className="form-label fw-bolder text-dark fs-6">
                                Image upload
                            </label>
                            <div>
                                <label
                                    htmlFor="event-img"
                                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 cursor-pointer"
                                >
                                    {imgFileName
                                        ? charValidate(imgFileName, 25)
                                        : "Choose File"}
                                </label>
                                <input
                                    id="event-img"
                                    type="file"
                                    accept="image/*"
                                    className="form-control form-control-lg form-control-solid mb-lg-0"
                                    placeholder="Upload Image"
                                    aria-label="Upload Image"
                                    onChange={handleChangeImageUpload}
                                    style={{
                                        visibility: "hidden",
                                    }}
                                // {...formik.getFieldProps("eventImg")}
                                />
                            </div>
                        </div>
                        <div className="fv-row mb-8">
                            <label className="form-label fw-bolder text-dark fs-6">
                                Description
                            </label>
                            <ReactQuill
                                theme="snow"
                                placeholder="Write something..."
                                modules={AddEditBlog.modules}
                                formats={AddEditBlog.formats}
                                value={formik.values.description}
                                onChange={(content) =>
                                    formik.setFieldValue("description", content)
                                }
                                style={{
                                    height: "8rem",
                                    marginBottom: "4rem",
                                }}
                            />
                            {formik.touched.description && formik.errors.description && (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                        <p style={{ color: "red" }}>Description is Required</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="w-100 d-flex p-5 align-items-center">
                        <Button
                            onClick={() => {
                                formik.resetForm();
                                handleClose(false);
                            }}
                            size="lg"
                            variant="secondary"
                        >
                            Cancel
                        </Button>
                        <div className="w-15px"></div>
                        <Button type="submit" size="lg" variant="primary">
                            {isAdding || isEditing ? "Loading..." : "Submit"}
                        </Button>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot,
    );
};

AddEditBlog.modules = {
    toolbar: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image"],
        [{ color: [] }],
    ],
};
AddEditBlog.formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
];

export default AddEditBlog;
