import { Box } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useMutation } from "react-query";
import * as Yup from "yup";
import { KTCard } from "../../_metronic/helpers";
import { addInfoNote, getAllEventList } from "../../api";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import useDebounce from "../../hooks/useDebounce";

const CreateNotes = ({ title = "" }: { title: string }) => {
  const [tableOptions, setTableOptions] = useState({
    skip: 0,
    take: 100000,
    hasNextPage: true,
    hasPrevPage: true,
    totalRowCount: undefined,
    id: "",
    SearchText: "",
  });

  const profileDetailsSchema = Yup.object().shape({
    title: Yup.string().required("Name is required"),
    description: Yup.string().required("Description Name is required"),
  });

  const {
    mutateAsync: infoNoteData,
    isLoading: isAdding,
    error: addError,
  } = useMutation("add-info", addInfoNote);

  const formik = useFormik({
    initialValues: {
      id: 0,
      eventList: "",
      infolist: "",
      title: "",
      description: "",
    },
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      let bodyParams = {
        id: 0,
        eventId: values.eventList,
        infoChirpId: 1,
        title: values.title,
        description: values.description,
      };
      const response = await infoNoteData(bodyParams);
      if (response.result) {
        snackActions.success(response?.message ?? "Added/Edited Successfully!");
        formik.resetForm();
      } else {
        snackActions.error(response?.message ?? "Something went wrong!");
      }
    },
  });
  const debounceSearchValue: string = useDebounce(
    tableOptions?.SearchText,
    500,
  );

  let defaultQuery = {
    id: "",
    skip: 0,
    take: 100000,
    SearchText: "",
  };

  const {
    mutateAsync: getEventData,
    data: listData,
    isLoading: isGettingListData,
  } = useMutation(getAllEventList);

  useEffect(() => {
    getEventData(defaultQuery);
  }, []);

  return (
    <Card className="p-6">
      {/* <PageTitle>Notes</PageTitle> */}
      <>
        <KTCard>
          <h2 className="mb-8" style={{ color: "#272781" }}>
            {`${title} : `}
          </h2>
          <form onSubmit={formik.handleSubmit} noValidate className="form">
            <Box>
              <Box display="flex" marginBottom="20px">
                <Box width={0.25}>
                  <label
                    className="form-label fw-bold"
                    style={{ marginLeft: 5 }}
                  >
                    Select Event lists
                  </label>
                  <select
                    {...formik.getFieldProps("eventList")}
                    className="form-select form-select-solid form-select-lg"
                  >
                    <option value="">Select Event</option>
                    {listData?.result?.map((data: any, index: any) => (
                      <option key={index?.id} value={data?.id}>
                        {data?.eventName}
                      </option>
                    ))}
                  </select>
                </Box>
                {/* <Button
                  variant="success"
                  style={{
                    width: "100px",
                    height: "45px",
                    fontSize: "14px",
                    marginLeft: "auto",
                  }}
                  type="submit"
                >
                  {isAdding ? "Loading.." : "Submit"}
                </Button> */}
              </Box>
              <Box>
                <div>
                  <label className="form-label fw-bolder text-dark fs-6">
                    Name :
                  </label>
                  <input
                    type="text"
                    placeholder="Name"
                    {...formik.getFieldProps("title")}
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  />
                  {formik.touched.title && formik.errors.title && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.title}</div>
                    </div>
                  )}
                </div>
                <div className="fv-row mb-8 mt-5">
                  <label className="form-label fw-bolder text-dark fs-6">
                    Description
                  </label>
                  <textarea
                    style={{ resize: "both", minHeight: "50px" }}
                    placeholder="Description"
                    aria-label="Description"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    {...formik.getFieldProps("description")}
                    cols={4}
                    rows={4}
                  />
                  {formik.touched.description && formik.errors.description && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.description}
                      </div>
                    </div>
                  )}
                </div>
              </Box>
            </Box>

            <Button
              type="submit"
              size="lg"
              variant="primary"
              className="d-flex ms-auto"
            >
              {isAdding ? "Loading..." : "Submit"}
            </Button>
          </form>
        </KTCard>
      </>
    </Card>
  );
};

// export default CreateNotes;
export default CreateNotes;
