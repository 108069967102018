import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { Box, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { useMutation } from "react-query";
import { KTCard } from "../../_metronic/helpers";
import ConfirmEventPopup from "../../_metronic/layout/components/common/ConfirmEventPopup";
import CustomTooltip from "../../_metronic/layout/components/common/CustomTooltip";
import { deleteInfo, getAllEventInfoTabsList } from "../../api";
import MuiTable from "../../components/table/MuiTable";
import { charValidate } from "../../components/utility";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import useDebounce from "../../hooks/useDebounce";
import AddEditInfoTab from "./addEditInfoTab";

const InfoTab: React.FC = () => {
  const tableRef = useRef();
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [paginationValue, setPaginationValue] = useState<any>(false);
  const [paginations, setPaginations] = useState<boolean>(false);
  const [pageSizeValue, setPageSizeValue] = useState<any>(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [tableOptions, setTableOptions] = useState({
    skip: 0,
    take: 10,
    roleId: 2,
    totalRowCount: undefined,
    SearchText: "",
  });

  let defaultQuery = {
    skip: 0,
    take: 1000,
    roleId: 2,
    SearchText: "",
  };

  const debounceSearchValue: string = useDebounce(
    tableOptions?.SearchText,
    500,
  );

  const {
    mutateAsync: getEventInfoTabData,
    data: adminData,
    isLoading: isGettingListData,
  } = useMutation(getAllEventInfoTabsList);

  useEffect(() => {
    getEventInfoTabs(tableOptions);
  }, []);

  useEffect(() => {
    getEventInfoTabs({
      ...tableOptions,
      SearchText: debounceSearchValue ?? "",
    });
  }, [debounceSearchValue]);

  const getEventInfoTabs = async (
    query:
      | {
          skip: number;
          take: number;
          roleId: number;
          SearchText: string;
        }
      | undefined,
  ) => {
    let response = await getEventInfoTabData(query);

    if (response?.result) {
      setTableOptions((prevState: any) => {
        return {
          ...prevState,
          skip: response?.pageDetail?.skip ?? 0,
          take: response?.pageDetail?.take ?? 10,
          SearchText: response?.pageDetail?.searchText ?? "",
          totalRowCount: response?.pageDetail?.count ?? 0,
        };
      });
    }
  };
  const handleCloseDelete = () => {
    setShowDeleteConfirmModal(false);
  };

  const handleModalClose = (isSubmit: boolean) => {
    setShowAddEditModal(false);
    setSelectedRow("");
    if (isSubmit) {
      getEventInfoTabs(defaultQuery);
    }
  };

  const { mutateAsync: deleteInfoData, isLoading: isDeleting } = useMutation(
    "delete-info",
    deleteInfo,
  );

  const deleteAdmin = async () => {
    let response = await deleteInfoData({ id: selectedRow?.id });
    if (response?.result) {
      snackActions.success(response?.message);
      handleCloseDelete();
      getEventInfoTabData(
        paginations
          ? {
              skip: `${paginationValue}` + 0,
              take: pageSizeValue,
              roleId: 2,
              SearchText: "",
            }
          : defaultQuery,
      );
    } else {
      snackActions.error(response?.message);
    }
  };

  const columns: GridColDef[] = [
    {
      field: "displayName",
      headerName: "Name",
      flex: 1,
      sortable: false,
      renderCell: (data: any) => (
        <CustomTooltip
          title={
            data?.row?.displayName ? (
              <h6 style={{ color: "white" }}>{data?.row?.displayName}</h6>
            ) : (
              "N/A"
            )
          }
        >
          <span>
            {data?.row?.displayName
              ? charValidate(data?.row?.displayName, 25)
              : "N/A"}
          </span>
        </CustomTooltip>
      ),
    },
    {
      field: "actions",
      headerName: "Action",
      flex: 0.1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (data) => (
        <Box display="flex" alignItems="center">
          <Tooltip title="Edit">
            <DriveFileRenameOutlineIcon
              onClick={() => {
                setSelectedRow(data.row);
                setShowAddEditModal(true);
                setIsEdit(true);
              }}
              sx={{ fontSize: 22, cursor: "pointer" }}
              color="primary"
            />
          </Tooltip>
          <Tooltip title="Delete">
            <DeleteSweepIcon
              onClick={() => {
                setSelectedRow(data.row);
                setShowDeleteConfirmModal(true);
              }}
              sx={{ fontSize: 22, ml: 1.7, cursor: "pointer" }}
              color="error"
            />
          </Tooltip>
        </Box>
      ),
    },
  ];

  const handlePagination = (values: { page: number; pageSize: number }) => {
    setPaginations(true);
    let page = values?.page;
    let pageSize = values?.pageSize;
    setPaginationValue(page);
    setPageSizeValue(values?.pageSize);
    let query: any = {
      skip:
        pageSize == tableOptions?.take ? (page === 0 ? 0 : `${page}` + 0) : 0,
      take: pageSize ?? 10,
      SearchText: "",
      roleId: 2,
    };
    getEventInfoTabs(query);
  };

  const handleChangeFilters = (fieldName: string, value: string) => {
    setTableOptions((prevState: any) => {
      return {
        ...prevState,
        [fieldName]: value,
      };
    });
  };

  const handleLastPage: any = () => {
    const totalPages = Math.ceil(
      (tableOptions?.totalRowCount ?? 0) / (tableOptions?.take ?? 10),
    );
    const lastPage = totalPages > 0 ? totalPages - 1 : 0;
    handlePagination({
      page: lastPage,
      pageSize: tableOptions?.take,
    });
  };

  const handleFirstPage: any = () => {
    const firstPage = 0;
    handlePagination({ page: firstPage, pageSize: tableOptions?.take });
  };

  return (
    <Card className="p-6">
      <>
        <KTCard>
          <h3
            style={{
              position: "absolute",
              top: "10px",
              left: "10px",
            }}
          >
            Info Tabs
          </h3>
          <>
            <MuiTable
              addLabel="Add Info Chirp"
              handleAddClick={() => {
                setShowAddEditModal(true);
                setSelectedRow("");
                setIsEdit(false);
              }}
              columns={columns}
              handlePagination={handlePagination}
              handleLastPage={handleLastPage}
              handleFirstPage={handleFirstPage}
              tableRef={tableRef}
              tableOptions={tableOptions}
              isRowClickable={false}
              isAddEnable={false}
              data={adminData ? adminData?.result : []}
              loading={isGettingListData}
              filters={
                <Box
                  pb={2}
                  mr={1.2}
                  display="flex"
                  alignItems="center"
                  justifyContent="end"
                >
                  <Box>
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Search"
                      aria-label="Search"
                      onChange={(e: any) =>
                        handleChangeFilters("SearchText", e?.target?.value)
                      }
                      value={tableOptions?.SearchText}
                    />
                  </Box>
                </Box>
              }
            />
          </>
        </KTCard>
        {showAddEditModal && (
          <AddEditInfoTab
            editData={selectedRow}
            show={showAddEditModal}
            handleClose={handleModalClose}
            isEdit={isEdit}
          />
        )}
        {showDeleteConfirmModal && (
          <ConfirmEventPopup
            showPopup={showDeleteConfirmModal}
            handleClose={handleCloseDelete}
            handleConfirmed={deleteAdmin}
            loading={false}
            title={"Delete info"}
            message={"Are you sure want to delete this info chirp?"}
          />
        )}
      </>
    </Card>
  );
};

export default InfoTab;
