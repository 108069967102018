// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Avatar, Box, CardContent } from "@mui/material";
import { deepOrange } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { KTCard } from "../../_metronic/helpers";
import {
  getEventInfoById,
  getInfoByEventChirpsId,
  getParticipantsByEventId
} from "../../api";
import { charValidate } from "../../components/utility";
import { APP_URLs } from "../../constants/appURLs";

interface Iparticipants {
  id: number;
  participantName: string;
  participantImage: string | number;
}

const EventDetails: React.FC = () => {
  const location = useLocation();
  const [isClicked, setIsClicked] = useState<any>("");
  const navigate = useNavigate();
  const { eventData }: any = location?.state;

  const {
    mutateAsync: getEventData,
    data: listData,
    isLoading: isGettingListData,
  } = useMutation(getEventInfoById);

  const {
    mutateAsync: getInforChirps,
    data: inforChirpsData,
    isLoading: isGettingInforChirps,
  } = useMutation(getInfoByEventChirpsId);

  const {
    mutateAsync: getParticipantByEventId,
    data: participantsListData,
    isLoading: isLoadingData,
  } = useMutation(getParticipantsByEventId);

  useEffect(() => {
    getParticipantByEventId({ eventId: eventData?.id });
  }, []);
  const AllParticipantsUsers = participantsListData?.result?.eventParticipants;
  useEffect(() => {
    getEventData({ eventId: eventData?.id, Skip: 0, take: 1000 });
  }, []);
  const InforArrData = listData?.result;
  const inforChipsDetails = inforChirpsData?.result;
  const [isParticipantsBtnClicked, setIsParticipantsBtnClicked] = useState<boolean>(true);


  const handleBtnSubmit = (id: number, item: any) => {
    getInforChirps({
      eventId: eventData?.id,
      eventInfoChirpId: id,
      itemName: item?.name,
    });
    setIsClicked(id);
    setIsParticipantsBtnClicked(false)
  };


  const allParticipantsClicked = () => {
    setIsClicked(true);
    setIsParticipantsBtnClicked(true)
  }

  return (
    <Card style={{ padding: "2rem" }}>
      <>
        <KTCard>
          <>
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 20 }}>
              <h3>
                Event Details
              </h3>
              <div >
                <Button
                  className="contained"
                  onClick={() =>
                    navigate("/all-events", { state: { replace: true } })
                  }
                >
                  <i className="bi bi-arrow-left-circle-fill"></i>
                  Back
                </Button>
              </div>
            </div>
            <div
              className="w-100 mb-7"
              style={{
                height: 1,
                borderBottom:
                  "1px dashed var(--bs-app-sidebar-light-separator-color)",
              }}
            ></div>
            <div style={{
              border: "1px solid #ffffff",
              borderRadius: "10px",
              // backgroundColor: "#D2042D",
              backgroundColor: "#009EF7",
              marginBottom: "20px",
              padding: "25px"
            }}>
              <div className="container m-0">
                <div className="row">
                  <div className="col-8">
                    <div className="row">
                      <div className="col-1">
                        <div>
                          <img
                            src={`${APP_URLs?.mediaBaseURL}${participantsListData?.result?.image}`}
                            alt=""
                            style={{ width: "65px", height: "64px", borderRadius: "50%", objectFit: "fill" }}
                          />
                        </div>
                      </div>
                      <div className="col-2" >
                        <h5 style={{ color: "white" }}>Event Name</h5>
                        <p style={{ color: "white" }}>{`${eventData?.eventName}`}</p>
                      </div>
                      <div className="col-2" >
                        <h5 style={{ color: "white" }}>Created By</h5>
                        <p style={{ color: "white" }}>{`${eventData?.createdBy}`}</p>
                      </div>
                      <div className="col-2">
                        <h5 style={{ color: "white" }}>Category Name</h5>
                        <p style={{ color: "white" }}>{`${eventData?.categoryName ?? "N/A"}`}</p>
                      </div>
                      <div className="col-2">
                        <h5 style={{ color: "white" }}>Location Name</h5>
                        <p style={{ color: "white" }}>{eventData?.locationName && eventData?.locationName !== "" ? eventData?.locationName : "N/A"}</p>
                      </div>
                      <div className="col-2">
                        <h5 style={{ color: "white" }}>Event Type</h5>
                        <p style={{ color: "white" }}>{`${eventData?.isMultipleEvent ? "Multi-day event" : "Single-day event"}`}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Box sx={{ p: 2 }}>
              <Button
                variant={isClicked !== "" ? "secondary" : "primary"}
                onClick={allParticipantsClicked}
                className="py-3 px-4 me-6 mb-2"
              >
                All Participants
              </Button>
              {InforArrData?.length === 0 ? (
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: "2rem",
                    fontSize: "20px",
                    color: "#B22222",
                  }}
                >
                  No data available !!
                </div>
              ) : (
                InforArrData?.map((item: any, index: any) => (
                  <Button
                    key={index}
                    variant={isClicked === item.id ? "primary" : "secondary"}
                    onClick={() => handleBtnSubmit(item?.id, item)}
                    className="py-3 px-4 me-6 mb-2"
                  >
                    {item?.displayName}
                  </Button>
                ))
              )}
            </Box>
            {/* </div> */}
            {/* <Container className="mt-10"> */}
            {inforChipsDetails?.length === 0 ? (
              <div
                style={{
                  textAlign: "center",
                  marginBottom: "2rem",
                  fontSize: "20px",
                  color: "#B22222",
                }}
              >
                No data available !!
              </div>
            ) : (
              <Row>
                {!isParticipantsBtnClicked &&
                  <div style={{ display: 'flex', flexWrap: 'wrap', paddingLeft: '27px' }}>
                    {inforChipsDetails?.map((item: any, index: number) => (
                      <Col key={index} md={4} style={{ paddingRight: "10px" }}>
                        <Card
                          style={{
                            border: '1px solid #ccc',
                            backgroundColor: '#f9f9f9',
                            marginBottom: '20px',
                          }}
                        >
                          <Card.Body>
                            <Card.Text><strong>Title: </strong> {item?.title}</Card.Text>
                            <Card.Text>
                              <strong>Description: </strong>{item?.description ? charValidate(item?.description, 20) : <span style={{ marginLeft: 2 }}>N/A</span>}
                            </Card.Text>
                            {isClicked === 84 && (
                              <Card.Text>
                                <strong>Website URL: </strong> {item?.webUrl}
                              </Card.Text>
                            )}
                            <Card.Text><strong>Created By: </strong>{item?.createdBy ?? eventData?.createdBy}</Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                  </div>
                }
              </Row>
            )}
            {isParticipantsBtnClicked &&
              <>
                <Box
                  sx={{
                    textAlign: "left",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                    paddingLeft: "16px"
                  }}
                >
                  {AllParticipantsUsers?.map(
                    (value: Iparticipants, index: number) => {
                      let imgUrl = value?.participantImage;
                      let userImg = `${APP_URLs?.mediaBaseURL}${imgUrl}`;
                      const renderAvatar = (participantName: string) => {
                        const initials = participantName
                          .split(" ")
                          .map((name) => name[0])
                          .join("")
                          .toUpperCase();

                        return (
                          <Avatar sx={{ bgcolor: deepOrange[500] }}>
                            {initials}
                          </Avatar>
                        );
                      };
                      return (
                        <>
                          <Card
                            key={index}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              backgroundColor: "#f9f9f9",
                              width: "25%",
                              paddingBottom: 2,
                            }}
                          >
                            {userImg ? (
                              <Box p={2}>
                                <Avatar
                                  alt={value?.participantName}
                                  src={userImg}
                                />
                              </Box>
                            ) : (
                              <Box p={2}>
                                {renderAvatar(value?.participantName)}
                              </Box>
                            )}
                            <CardContent>
                              <h6 className="text-left">
                                {value?.participantName}
                              </h6>
                            </CardContent>
                          </Card>
                        </>
                      );
                    },
                  )}
                </Box>

              </>
            }
            {/* </Container> */}
            {/* </div> */}
          </>
        </KTCard>
      </>
    </Card>
  );
};

export default EventDetails;
