import { FC, lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../_metronic/assets/ts/_utils";
import { WithChildren } from "../_metronic/helpers";
import { MasterLayout } from "../_metronic/layout/MasterLayout";
import AccountPage from "../pages/accounts/AccountPage";
import Blog from "../pages/Blog";
import EventCategory from "../pages/event/EventCategory";
import EventDetails from "../pages/event/EventDetails";
import GoogleAdds from "../pages/google-adds/GoogleAdds";
import Help from "../pages/help/Help";
import InfoTab from "../pages/info-tab/InfoTab";
import ParentInfoTab from "../pages/info-tab/ParentInfoTab";
import ProfilePage from "../pages/profile/ProfilePage";

const PrivateRoutes = () => {
  const Event = lazy(() => import("../pages/event/Event"));
  const UserList = lazy(() => import("../pages/user/Users"));
  const AdminUser = lazy(() => import("../pages/admin/AdminUser"));
  const Location = lazy(() => import("../pages/location/Locations"));
  const Dashboard = lazy(() => import("../pages/dashboard/Dashboard"));
  const Configuration = lazy(
    () => import("../pages/configuration/Configuration"),
  );
  const TermsConditions = lazy(
    () => import("../pages/terms-conditions/TermsConditions"),
  );
  const PrivacyPolicy = lazy(
    () => import("../pages/privacy-policy/PrivacyPolicy"),
  );

  const AboutApp = lazy(() => import("../pages/about-app/AboutApp"));

  const routes = [
    { pathname: "/auth/*", element: <Navigate to="/dashboard" /> }, // <----- Redirect to Dashboard after success login/registartion
    { pathname: "/all-events", element: <Event /> },
    { pathname: "/event/details", element: <EventDetails /> },
    { pathname: "/configuration", element: <Configuration /> },
    { pathname: "/terms-conditions", element: <TermsConditions /> },
    { pathname: "/privacy-policy", element: <PrivacyPolicy /> },
    { pathname: "/about-app", element: <AboutApp /> },
    { pathname: "/blog", element: <Blog /> },
    { pathname: "/profile", element: <ProfilePage /> },
    { pathname: "/account/setting", element: <AccountPage /> },
    { pathname: "*", element: <Navigate to="/dashboard" /> }, // <----- Redirect to Page not found if no route match
    { pathname: "/user/list", element: <UserList /> },
    { pathname: "/event/infotab", element: <ParentInfoTab /> },
    { pathname: "/dashboard", element: <Dashboard /> },
    { pathname: "/location", element: <Location /> },
    { pathname: "/event/category", element: <EventCategory /> },
    { pathname: "/admin-user", element: <AdminUser /> },
    { pathname: "/google-advert", element: <GoogleAdds /> },
    { pathname: "/help", element: <Help /> },
    { pathname: "/event/info-list", element: <InfoTab /> },
  ];

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {routes.map((route) => (
          <Route
            key={route.pathname}
            path={route.pathname}
            element={<SuspensedView>{route.element}</SuspensedView>}
          />
        ))}
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };

