import { Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useMutation } from "react-query";
import { KTCard } from "../../_metronic/helpers";
import { getAllEventInfoTabsList } from "../../api";
import AddCheckList from "./AddChecklist";
import AddChoiseList from "./AddChoiceList";
import AddDocument from "./AddDocument";
import AddImg from "./AddImg";
import RSVP from "./AddRSVP";
import AddSocialMedia from "./AddSocialMedia";
import AddVotePoll from "./AddVotePoll";
import AddWebsite from "./AddWebsite";
import CreateNotes from "./CreateNotes";

const ParentInfoTab: React.FC = () => {
  const [selectedValue, setSelectedValue] = useState("");

  const handleChange = (e: any) => {
    let selectedArr = e.target.value;
    setSelectedValue(selectedArr);
    // console.log(e.target.options[e.target.selectedIndex].textContent);
  };

  const {
    mutateAsync: getEventInfoTabData,
    data: infoListData,
    isLoading: isGettingListData,
  } = useMutation(getAllEventInfoTabsList);

  useEffect(() => {
    getEventInfoTabData({ skip: 0, take: 10, SearchText: "" });
  }, []);

  const InfoTabArray = infoListData?.result;

  return (
    <Card className="p-6">
      <KTCard>
        <h3 style={{ marginBottom: "15px", marginLeft: "10px" }}>
          Create Info Tabs
        </h3>
        <Divider sx={{ mb: 2 }} />
        <Container fluid>
          <Row>
            <Col xs={3}>
              <label className="form-label fw-bold" style={{ marginLeft: 5 }}>
                Select Action
              </label>
              <select
                className="form-select form-select-solid form-select-lg"
                onChange={handleChange}
                value={selectedValue}
              >
                <option value="">Select Action</option>
                {InfoTabArray?.map((data: any, index: any) => (
                  <option key={index?.id} value={data?.id}>
                    {data?.displayName}
                  </option>
                ))}
              </select>
            </Col>
            <Divider sx={{ mt: 5, mb: 1, fontSize: 10 }} />
          </Row>
          {selectedValue === "4" && <RSVP title="Add RSVP" />}
          {selectedValue === "1" && <CreateNotes title="Create Note" />}
          {selectedValue === "28" && <AddImg title="Add Image" />}
          {selectedValue === "2" && <AddDocument title="Add Document" />}
          {selectedValue === "3" && <AddWebsite title="Add Website" />}
          {selectedValue === "6" && <AddCheckList title="Add Checklist" />}
          {selectedValue === "7" && <AddChoiseList title="Add Choicelist" />}
          {selectedValue === "5" && <AddVotePoll title="Add Poll" />}
          {selectedValue === "8" && <AddSocialMedia title="Add Social Media" />}
        </Container>
      </KTCard>
    </Card>
  );
};

export default ParentInfoTab;
