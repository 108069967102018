import { useFormik } from "formik";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useMutation } from "react-query";
import * as Yup from "yup";
import { KTCard } from "../../_metronic/helpers";
import { addInfoRSVP } from "../../api";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import CommonDropdown from "./CommonDropdown";

const RSVP = ({ title = "" }: { title: string }) => {
  const profileDetailsSchema = Yup.object().shape({
    description: Yup.string().required("Description Name is required"),
    title: Yup.string().required("Title Name is required"),
    date: Yup.string().required("Date is required"),
    eventList: Yup.string().required("This field is required"),
    // infochirps: Yup.string().required("This field is required"),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      date: "",
      eventList: "",
    },
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      let bodyParams = {
        id: 0,
        title: values.title,
        description: values.description,
        eventId: values?.eventList,
        infoChirpId: 7,
        date: values?.date,
      };
      const response = await addRsvp(bodyParams);
      if (response.result) {
        snackActions.success(response?.message ?? "Added Successfully!");
        formik.resetForm();
      } else {
        snackActions.error(response?.message ?? "Something went wrong!");
      }
    },
  });

  const {
    mutateAsync: addRsvp,
    data: rsvpData,
    isLoading: isAddingRsvp,
  } = useMutation(addInfoRSVP);

  return (
    <Card className="p-6">
      <>
        <KTCard>
          <>
            <h2 className="mb-8" style={{ color: "#272781" }}>
              {`${title} : `}
            </h2>
            <div>
              <form onSubmit={formik.handleSubmit} noValidate className="form">
                <CommonDropdown formik={formik} />
                <Container fluid className="mt-5">
                  <Row>
                    <Col xs={6}>
                      <label className="form-label fw-bold">Title</label>
                      <input
                        placeholder="Enter Title"
                        type="text"
                        autoComplete="off"
                        {...formik.getFieldProps("title")}
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      />
                      {formik.touched.title && formik.errors.title && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.title}
                          </div>
                        </div>
                      )}
                    </Col>
                    <Col xs={6}>
                      <label className="form-label fw-bold">
                        Expire Date of RSVP
                      </label>
                      <input
                        placeholder="Enter Description"
                        type="date"
                        autoComplete="off"
                        {...formik.getFieldProps("date")}
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      />
                      {formik.touched.date && formik.errors.date && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.date}
                          </div>
                        </div>
                      )}
                    </Col>
                    <Col xs={6} className="mt-5">
                      <label className="form-label fw-bold">Description</label>
                      <textarea
                        style={{ resize: "both", minHeight: "50px" }}
                        placeholder="Enter Description"
                        aria-label="Description"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        {...formik.getFieldProps("description")}
                        cols={4}
                        rows={4}
                      />
                      {formik.touched.description && formik.errors.description && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.description}
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Container>
                <Button
                  size="lg"
                  variant="primary"
                  type="submit"
                  className="d-flex ms-auto"
                >
                  {isAddingRsvp ? "Loading..." : "Submit"}
                </Button>
              </form>
            </div>
          </>
        </KTCard>
      </>
    </Card>
  );
};

export default RSVP;
