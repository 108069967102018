import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { Box, Button, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { useMutation } from "react-query";
import { KTCard } from "../../_metronic/helpers";
import ConfirmEventPopup from "../../_metronic/layout/components/common/ConfirmEventPopup";
import CustomTooltip from "../../_metronic/layout/components/common/CustomTooltip";
import { categoryBulkUpload, deleteCategoryEvent, getAllEventCategoryList } from "../../api";
import MuiTable from "../../components/table/MuiTable";
import { charValidate } from "../../components/utility";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import useDebounce from "../../hooks/useDebounce";
import AddEditEventCategory from "./AddEditEventCategory";
import BulkUploadDialog from './BulkUpload';

const EventCategory: React.FC = () => {
  const tableRef = useRef();
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [excelFileName, setExcelFileName] = useState([]);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [paginationValue, setPaginationValue] = useState<any>(false);
  const [paginations, setPaginations] = useState<boolean>(false);
  const [pageSizeValue, setPageSizeValue] = useState<any>(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [tableOptions, setTableOptions] = useState({
    skip: 0,
    take: 10,
    hasNextPage: true,
    hasPrevPage: true,
    totalRowCount: undefined,
    id: "",
    SearchText: "",
  });

  let defaultQuery = {
    skip: 0,
    take: 10,
    id: "",
    SearchText: "",
  };
  const debounceSearchValue: string = useDebounce(
    tableOptions?.SearchText,
    500,
  );

  const {
    mutateAsync: getEventCategoryData,
    data: listData,
    isLoading: isGettingListData,
  } = useMutation(getAllEventCategoryList);

  useEffect(() => {
    getEventCategory({
      ...tableOptions,
      SearchText: debounceSearchValue ?? "",
    });
  }, [debounceSearchValue]);

  const getEventCategory = async (
    query:
      | {
        skip: number;
        take: number;
        id: string;
        SearchText: string;
      }
      | undefined,
  ) => {
    let response = await getEventCategoryData(query);
    if (response?.result) {
      setTableOptions((prevState: any) => {
        return {
          ...prevState,
          skip: response?.pageDetail?.skip ?? 0,
          take: response?.pageDetail?.take ?? 10,
          SearchText: response?.pageDetail?.searchText ?? "",
          totalRowCount: response?.pageDetail?.count ?? 0,
        };
      });
    }
  };

  const { mutateAsync: deleteCategoryData, isLoading: isDeletingCategory } =
    useMutation("delete-category", deleteCategoryEvent);

  const handleModalClose = (isSubmit: boolean) => {
    setShowAddEditModal(false);
    setIsSubmitClicked(false);
    if (isSubmit) {
      getEventCategoryData(defaultQuery);
    }
  };

  const columns: GridColDef[] = [
    {
      field: "categoryName",
      headerName: "Name",
      sortable: false,
      flex: 1,
      renderCell: (data: any) => (
        <CustomTooltip
          title={
            data?.row?.categoryName ? (
              <h6 style={{ color: "white" }}>{data?.row?.categoryName}</h6>
            ) : (
              "N/A"
            )
          }
        >
          <span>
            {data?.row?.categoryName
              ? charValidate(data?.row?.categoryName, 40)
              : "N/A"}
          </span>
        </CustomTooltip>
      ),
    },
    {
      field: "actions",
      headerName: "Action",
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (data) => (
        <Box display="flex" alignItems="center">
          <Tooltip title="Edit">
            <DriveFileRenameOutlineIcon
              onClick={() => {
                setSelectedRow(data?.row);
                setShowAddEditModal(true);
                setIsEdit(true);
              }}
              sx={{ fontSize: 22, cursor: "pointer" }}
              color="primary"
            />
          </Tooltip>
          <Tooltip title="Delete">
            <DeleteSweepIcon
              onClick={() => {
                setSelectedRow(data?.row);
                setShowDeleteConfirmModal(true);
              }}
              sx={{ fontSize: 22, ml: 1.7, cursor: "pointer" }}
              color="error"
            />
          </Tooltip>
        </Box>
      ),
    },
  ];

  const handleCloseDelete = () => {
    setShowDeleteConfirmModal(false);
  };

  const deleteCategory = async () => {
    let response = await deleteCategoryData({ id: selectedRow?.id });
    if (response?.result) {
      snackActions.success(response?.message);
      handleCloseDelete();
      getEventCategoryData(
        paginations
          ? {
            skip: `${paginationValue}` + 0,
            take: pageSizeValue,
            id: "",
            SearchText: "",
          }
          : defaultQuery,
      );
    } else {
      snackActions.error(response?.message);
    }
  };
  const handleChangeFilters = (fieldName: string, value: string) => {
    setTableOptions((prevState: any) => {
      return {
        ...prevState,
        [fieldName]: value,
      };
    });
  };

  const handlePagination = (values: {
    page: number | string;
    pageSize: number;
  }) => {
    setPaginations(true);
    let page = values?.page;
    let pageSize = values?.pageSize;
    setPaginationValue(page);
    setPageSizeValue(values?.pageSize);
    let query: any = {
      id: "",
      skip:
        pageSize == tableOptions?.take ? (page === 0 ? 0 : `${page}` + 0) : 0,
      take: pageSize ?? 10,
      SearchText: debounceSearchValue ?? "",
    };
    setTableOptions((prevState: any) => {
      return {
        ...prevState,
        skip: tableOptions?.take ? (page === 0 ? 0 : `${page}` + 0) : 0,
        take: pageSize ?? 10,
      };
    });
    getEventCategory(query);
  };

  const handleLastPage: any = () => {
    const totalPages = Math.ceil(
      (tableOptions?.totalRowCount ?? 0) / (tableOptions?.take ?? 10),
    );
    const lastPage = totalPages > 0 ? totalPages - 1 : 0;
    handlePagination({
      page: lastPage,
      pageSize: tableOptions?.take,
    });
  };

  const handleFirstPage: any = () => {
    const firstPage = 0;
    handlePagination({ page: firstPage, pageSize: tableOptions?.take });
  };

  const fileInputRef = useRef<any>(null);

  const {
    mutateAsync: bulkUploadAPI,
    data: bulkUploadExcelAPIdata,
    isLoading: isLoading,
  } = useMutation(categoryBulkUpload);

  const handleExcelFileChange = (e: any) => {
    setSelectedFile(e?.target?.files[0] ?? "")
    setExcelFileName(e?.target?.files[0]?.name ?? "");
    e.preventDefault();
  }

  const uploadSubmit = async () => {
    if (excelFileName) {
      let formData = new FormData();
      formData.append("file", selectedFile);
      let response: any = await bulkUploadAPI(formData);
      setIsSubmitClicked(true);
      if (response?.result !== null && response?.result[0].status === "Success") {
        // setShowDialog(false)
        getEventCategory(defaultQuery)
        fileInputRef.current.value = '';
        snackActions.success(response.message ?? "Categories uploaded successfully!")
      } else if (response?.result[0].status === "Failed") {
        snackActions.error(response.result[0].message ?? "Something went wrong!")
      }
    }
  }

  return (
    <Card className="p-6">
      <>
        <KTCard>
          <Button
            variant="contained"
            color='success'
            size='large'
            sx={{ position: "absolute", right: "33rem", top: "0.5rem" }}
            onClick={() => setShowDialog(true)}>
            <CloudUploadOutlinedIcon />&nbsp; &nbsp; Bulk Upload
          </Button>
          <h3 style={{ position: "absolute", top: "10px", left: "10px" }}>
            Categories
          </h3>
          <>
            <MuiTable
              addLabel="Add Category"
              handleAddClick={() => {
                setShowAddEditModal(true);
                setSelectedRow("");
              }}
              columns={columns}
              tableRef={tableRef}
              isRowClickable={true}
              tableOptions={tableOptions}
              handlePagination={handlePagination}
              handleLastPage={handleLastPage}
              handleFirstPage={handleFirstPage}
              isAddEnable={true}
              data={listData ? listData?.result : []}
              loading={isGettingListData}
              filters={
                <Box
                  pb={2}
                  mr={1.2}
                  display="flex"
                  alignItems="center"
                  justifyContent="end"
                >
                  <Box>
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Search"
                      aria-label="Search"
                      onChange={(e: any) =>
                        handleChangeFilters("SearchText", e?.target?.value)
                      }
                      value={tableOptions?.SearchText}
                    />
                  </Box>
                </Box>
              }
            />
          </>
        </KTCard>
        {showAddEditModal && (
          <AddEditEventCategory
            editData={selectedRow}
            show={showAddEditModal}
            handleClose={handleModalClose}
            isEdit={isEdit}
          />
        )}
        {showDeleteConfirmModal && (
          <ConfirmEventPopup
            showPopup={showDeleteConfirmModal}
            handleClose={handleCloseDelete}
            handleConfirmed={deleteCategory}
            loading={isDeletingCategory}
            title={"Delete Category"}
            message={"Are you sure want to delete this category?"}
          />
        )}
        {showDialog && (
          <BulkUploadDialog
            open={showDialog}
            onClose={() => { setShowDialog(false); setIsSubmitClicked(false); }}
            handleExcelFileChange={handleExcelFileChange}
            uploadSubmit={uploadSubmit}
            isLoading={isLoading}
            selectedFile={selectedFile ?? ""}
            fileInputRef={fileInputRef}
            bulkUploadExcelAPIdata={bulkUploadExcelAPIdata}
            isSubmitClicked={isSubmitClicked}
          />
        )}
      </>
    </Card>
  );
};

export default EventCategory;
