import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { APP_URLs, config } from "../../../constants/appURLs";
import { ILoginUserPayload } from "../../../constants/types";
import { snackActions } from "../../../helpers/SnackUtilsConfigurator";

// export const registerUser: any = createAsyncThunk(
//   "auth/register",
//   async (
//     {
//       firstName,
//       lastName,
//       email,
//       password,
//       confirmPassword,
//     }: IRegisterUserPayload,
//     { rejectWithValue },
//   ) => {
//     try {
//       const config = {
//         headers: {
//           "Content-Type": "application/json",
//         },
//       };
//       await axios.post(
//         `${BASE_URL}/register`,
//         { firstName, lastName, email, password, confirmPassword },
//         config,
//       );
//       debugger;
//     } catch (error: any) {
//       // return custom error message from backend if present
//       if (error.response && error.response.data.message) {
//         return rejectWithValue(error.response.data.message);
//       } else {
//         return rejectWithValue(error.message);
//       }
//     }
//   },
// );

export const userLogin: any = createAsyncThunk(
  "auth/login",
  async ({ username, password }: ILoginUserPayload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        APP_URLs.authEndPoints.login,
        { username, password, isUser: false },
        config,
      );
      if (data?.result?.token) {
        localStorage.setItem("userToken", data?.result?.token);
        localStorage.setItem("userInfo", JSON.stringify(data?.result));
        snackActions.success(data.message ?? "LoggedIn successfully");
        setTimeout(() => {
          window.location.reload();
        });
      } else if (data?.result === "false" || "" || null) {
        snackActions.dismissibleError(data.message ?? "Something went wrong!");
      }
    } catch (error: any) {
      snackActions.dismissibleError(
        "The username or password doesn't match our records",
      );
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

// export const userLogout: any = createAsyncThunk(
//   "auth/logout",
//   async (_, { rejectWithValue }) => {
//     try {
//       const { data } = await axios.put(
//         APP_URLs.authEndPoints.logout,
//         { token: userToken },
//         config,
//       );
//       if (data?.status) {
//         localStorage.clear();
//         snackActions.success(data.message ?? "LoggedOut Successfully");
//         window.location.reload();
//       } else {
//         snackActions.dismissibleError(data.message ?? "Something went wrong!");
//       }
//     } catch (error: any) {
//       if (error.response && error.response.data.message) {
//         return rejectWithValue(error.response.data.message);
//       } else {
//         return rejectWithValue(error.message);
//       }
//     }
//   },
// );
