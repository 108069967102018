import { Col, Form, Row } from "react-bootstrap";

const OTPInput = ({
  length,
  otp,
  setOTP,
}: {
  length: number;
  otp: string[];
  setOTP: Function;
}) => {
  let document: any = window?.document ?? "";

  const handleInputChange = (index: number, value: any) => {
    if (isNaN(value)) return; // Allow only numeric input

    const newOTP = [...otp];
    newOTP[index] = value;
    setOTP(newOTP);

    if (value !== "" && index < length - 1) {
      if (document) {
        document?.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  const handleInputKeyDown = (index: number, e: any) => {
    if (e.key === "Backspace" && index > 0 && otp[index] === "") {
      if (document) {
        document?.getElementById(`otp-input-${index - 1}`).focus();
      }
    }
  };

  return (
    <Row>
      <label className="form-label fw-bolder text-gray-900 fs-6">
        Enter OTP (that is sent on your email)
      </label>
      {otp.map((digit, index) => (
        <Col key={index}>
          <Form.Control
            id={`otp-input-${index}`}
            type="text"
            value={digit}
            onChange={(e) => handleInputChange(index, e.target.value)}
            onKeyDown={(e) => handleInputKeyDown(index, e)}
            maxLength={1}
            name="otpInput"
            style={{ paddingLeft: "18px" }}
          />
        </Col>
      ))}
    </Row>
  );
};

export default OTPInput;
