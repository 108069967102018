import { PageLink } from "../../_metronic/layout/core";

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: "Profile",
    path: "/profile",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
];

const ProfilePage = () => (
  <div>
    Hello, this is profile page
  </div>
  //     <Route
  //       index
  //       element={<Navigate to="/profile" />}
  //     />
  //   </Route>
  // </Routes>
);

export default ProfilePage;
