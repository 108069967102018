import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import * as React from "react";

type Props = {
  children: React.ReactComponentElement<any>;
  title: React.ReactComponentElement<any> | string;
};
const CustomTooltip = ({ children, title }: Props) => {
  const TooltipElement: any = styled(
    ({ className, ...props }: TooltipProps) => (
      <Tooltip {...props} arrow classes={{ popper: className }} />
    ),
  )(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  return <TooltipElement title={title}>{children}</TooltipElement>;
};

export default CustomTooltip;
