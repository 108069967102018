const BASE_URL = process.env.REACT_APP_BASE_URL;

export const userToken = localStorage.getItem("userToken");

// configure header's Content-Type as JSON
export const config = {
  headers: {
    ContentType: "application/json",
    accept: "application/json",
    Authorization: `Bearer ${userToken}`,
  },
};

export const APP_URLs = {
  mediaBaseURL: "https://invitemmedia.s3.eu-north-1.amazonaws.com/",
  genericUserAPI: `${BASE_URL}/user/get-user-by-role-id`,
  mediaUpload: `${BASE_URL}/media/upload`,
  authEndPoints: {
    login: `${BASE_URL}/user/authenticate`,
    sendOTP: `${BASE_URL}/user/send-otp`,
    verifyOTP: `${BASE_URL}/user/verify-otp`,
    resetPassword: `${BASE_URL}/user/reset-password`,
    resetUserPassword: `${BASE_URL}/user/reset-password`,
  },
  termsAndCondition: {
    getTerms: `${BASE_URL}/Content/content?SearchText=terms`,
    addTerms: `${BASE_URL}/Content`,
  },
  privacyAndPolicy: {
    getPrivacy: `${BASE_URL}/Content/content?SearchText=privacy`,
    addPrivacy: `${BASE_URL}/Content`,
  },
  aboutUs: {
    getAboutUs: `${BASE_URL}/Content/content?SearchText=aboutus`,
    addAboutUs: `${BASE_URL}/Content`,
  },
  commonEndPoints: {
    getCountries: `${BASE_URL}/location/countries/?Skip=0&Take=1000`,
    getEventCategory: `${BASE_URL}/category/?Skip=0&Take=1000`,
    getLocation: `${BASE_URL}/location`,
    getMostSearchedLocation: `${BASE_URL}/location/top-search-location`,
    getStates: `${BASE_URL}/location/states`,
    getCities: `${BASE_URL}/location/cities`,
    getUsers: `${BASE_URL}/user/user-count`,
    blockUsers: `${BASE_URL}/user`,
    getProfile: `${BASE_URL}/user/get-profile`,
    getDashboardInfo: `${BASE_URL}/dashboard`,
    bulkUpload: `${BASE_URL}/category/bulk-upload`,
  },
  appUser: {
    appCustomerList: `${BASE_URL}/admin/userList`,
  },
  AdminUser: {
    addAdmin: `${BASE_URL}/user/create-admin`,
    editAdmin: `${BASE_URL}/user/update-admin`,
    deleteAdmin: `${BASE_URL}/user`,
  },
  event: {
    getAllEvents: `${BASE_URL}/event`,
    getEventInfoById: `${BASE_URL}/event-info-chirps`,
    getEventByInforChipsId: `${BASE_URL}/event-info-chirps`,
    getAllUserList: `${BASE_URL}/event/contacts`,
    addEvents: `${BASE_URL}/event`,
    addCategoryEvents: `${BASE_URL}/category`,
    deleteCategoryEvents: `${BASE_URL}/category`,
    deleteEvents: `${BASE_URL}/event`,
    getAllEventCategroyList: `${BASE_URL}/category`,
    addParticipants: `${BASE_URL}/event/event-participants`,
    getParticipantsByEventIds: `${BASE_URL}/event`,
    getAllEventContacts: `${BASE_URL}/event/contacts`,
  },
  location: {
    getLocationList: `${BASE_URL}/location`,
    addLocation: `${BASE_URL}/location`,
    deleteLocation: `${BASE_URL}/location`,
  },
  category: {
    getAllCategory: `${BASE_URL}/category?Skip=0&Take=1000&SearchText=%27%27&OrderByColumn=%22true%22&OrderByDirection=%22true%22&id=1`,
  },
  blog: {
    genericURL: `${BASE_URL}/blog`,
  },
  infotabs: {
    getAllInfoTabs: `${BASE_URL}/info-chirps`,
    addNote: `${BASE_URL}/event-info-chirps/note`,
    addWebURL: `${BASE_URL}/event-info-chirps/web-link`,
    addSocial: `${BASE_URL}/event-info-chirps/social-media-url`,
    addImage: `${BASE_URL}/event-info-chirps/image`,
    addDocument: `${BASE_URL}/event-info-chirps/document`,
    addRsvp: `${BASE_URL}/event-info-chirps/rsvp`,
    addCheckList: `${BASE_URL}/event-info-chirps/add-checklist`,
    addChoiceList: `${BASE_URL}/event-info-chirps/add-choicelist`,
    addPoll: `${BASE_URL}/event-info-chirps/poll`,
  },
};
